import React from "react";
import { createRef } from 'react';

import { Row, Col, Button, ModalBody } from "reactstrap";
import QRCard from './QRCard.js'

import WhiteLiveNyetLogo from 'assets/img/img-livenyte/qr_white_logo.png'
import DarkLiveNyetLogo from 'assets/img/img-livenyte/qr_dark_logo.png'

const httpRgx = /^https?:\/\//;

class QRCardModal extends React.Component {
    constructor(props) {
      super(props);
      const { url } = props
      console.log("QRCardModal props", props)
        this.state = {
          qrRef: createRef(),
          url: url,
          white: '#ffffff',
          livenytePeach: '#FF7676',
          transparent: 'transparent',
          black: '#000000',
          softBlack: '#484848',
          printerFriendly: false,
          highContrast: false,
          liveNyteColors: true, 
        }
        this.downloadQrCode = this.downloadQrCode.bind(this);
        this.setLiveNyteColors = this.setLiveNyteColors.bind(this);
        this.setHighContrast = this.setHighContrast.bind(this);
        this.setPrinterFriendly = this.setPrinterFriendly.bind(this);

    }

    componentDidMount() {

    }

    downloadQrCode = e => {
      const {qrRef, url, liveNyteColors, livenytePink, black} = this.state 
      e.preventDefault();
      
      const qrCanvas = qrRef.current.querySelector('canvas')
      // const ctx = qrCanvas.getContext("2d");
      // const grad = ctx.createLinearGradient(0,0,qrCanvas.width, qrCanvas.height)
      // grad.addColorStop(0, "#FF4F83");
      // grad.addColorStop(1, "#ff644f"); 
      // ctx.clearRect(0,0,qrCanvas.width, qrCanvas.height)
      // ctx.rect(0, 0, qrCanvas.width, qrCanvas.height);

      // ctx.fillStyle = "#f06482";

      const qrImage = qrCanvas.toDataURL("image/png"),
          qrAnchor = document.createElement('a'),
          fileName = url.replace(httpRgx, '').trim();
      qrAnchor.href = qrImage;
      qrAnchor.download = fileName+'_QrCode.png';
      document.body.appendChild(qrAnchor);
      qrAnchor.click();
      document.body.removeChild(qrAnchor);
    
      // handleQrReset();
      // setDownloaded(true);
    }

    setLiveNyteColors() {
      this.setState({
        liveNyteColors: true,
        highContrast: false,
        printerFriendly: false 
      })
    }

    setHighContrast() {
      this.setState({
        liveNyteColors: false,
        highContrast: true,
        printerFriendly: false 
      })
    }

    setPrinterFriendly() {
      this.setState({
        liveNyteColors: false,
        highContrast: false,
        printerFriendly: true 
      })
    }





  render() {
    const {qrRef, url, black, softBlack, white, transparent, livenytePeach, customImg, noImg, liveNyteColors, highContrast, printerFriendly} = this.state 
    const qrColor = liveNyteColors ? white : highContrast ? white : softBlack
    const qrBgColor = liveNyteColors ? livenytePeach : highContrast ? black : white
    const logo = liveNyteColors ? WhiteLiveNyetLogo : highContrast ? WhiteLiveNyetLogo : DarkLiveNyetLogo


    
    return (
        <ModalBody>
        <QRCard
					qrRef={qrRef}
					url={url}
					qrColor={qrColor}
					bgColor={qrBgColor}
					customImg={logo}
					noImg={false}
          liveNyteColors={liveNyteColors}
				/>
        <Button
          color="primary"
          onClick={(e) => this.setLiveNyteColors(e)}
          size="sm"
          className= "bg-gradient-livenyte"
          
        >
        Web
      
        </Button>
        <Button
          color="darkMode"
          onClick={(e) => this.setHighContrast(e)}
          size="sm"
          className= ""
          
        >
        High-Contrast
      
        </Button>
        <Button
          color="darkMode"
          onClick={(e) => this.setPrinterFriendly(e)}
          size="sm"
          className= ""
          outline
        >
        Printer-Friendly
      
        </Button>
        <br/>
        <Button
          color="success"
          onClick={(e) => this.downloadQrCode(e)}
          size="sm"
          className= "mt-4"
          
        >
        Download
      
        </Button>
    
      </ModalBody>
    );
  }
}

export default QRCardModal;
