import {QRCodeCanvas, QRCodeSVG} from 'qrcode.react';
import LiveNyetLogo from 'assets/img/img-livenyte/qr_white_logo.png'

export default function QRCard({ qrRef, url, bgColor, qrColor, customImg, noImg, liveNyteColors }) {
	let imgCustom =  undefined;

	noImg ?
		imgCustom = null :
		customImg ?
			imgCustom = customImg :
			imgCustom = LiveNyetLogo;

	// const qrCanvas = qrRef.current.querySelector('canvas')
	// const ctx = qrCanvas.getContext("2d");
	// const grad = ctx.createLinearGradient(0,0,280,0)
	// grad.addColorStop(0, "#FF4F83");
	// grad.addColorStop(1, "#ff644f"); 

	return (
		<article className="qr-card mx-auto">
			<div className={"qr-box " }
				ref={qrRef}
				style={{ backgroundColor: bgColor }}
				>
			<div className={(liveNyteColors ? 'bg-gradient-livenyte____' : '')}>

				<QRCodeCanvas
					size={250}
					value={url ? url : 'https://livenyte.com'}
					bgColor={bgColor}
					fgColor={qrColor}
					level='H'
					includeMargin
					imageSettings={{
						src: imgCustom,
						height: 50,
						width: 50,
						excavate: true,
					}}
				/>
			</div>
			</div>
		</article>
	);
}