import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { 
  CardHeader, 
  Col, 
  Collapse,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input, 
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,

} from "reactstrap";

import { postsActions } from '_actions';
import { Button, PostUpload, Post} from "components";
import {  atLeastOneLetter } from '_helpers';
import { ClipLoader } from 'react-spinners';
import { notificationMessageActions } from "_actions"

class PostsAccordion extends React.Component {
  constructor(props) {
    super(props);
    const isOwner = this.props.isOwner
    this.state = {
      openedCollapses: this.props.defaultOpened !== undefined ?  ["collapseOne"] : [""],
      createPostModal: false,
      post_caption: '',
      userSelectedPostImage: false ,
      post_image: null, 
      post_imageUrl: '',
      isOwner: isOwner,
      post_title: '',
      actionButtonPressed: false, 

    };
    this.toggleCreatePostModal = this.toggleCreatePostModal.bind(this);
    this.createPost = this.createPost.bind(this);
    this.handlePostImageChange = this.handlePostImageChange.bind(this);

    // if (owner) {
    //   this.props.dispatch(postsActions.getMyPosts())
    // } else {
    //   this.props.dispatch(postsActions.getPosts())
    // }
  }

  toggleCreatePostModal() {
    this.setState({
      createPostModal: !this.state.createPostModal
    });
  }

  handlePostImageChange(imageData) {
    // console.log(imageData)
    // var newState = this.state
    // newState['userSelectedImage'] = imageData.newImage
    // newState['image'] = imageData.newImage ? imageData.state.imagePreviewUrl : null
    console.log("imageData", imageData)

    const userSelectedImage = imageData.newImage
    const post_image = imageData.newImage ? imageData.state.imagePreviewUrl : null
    this.setState({userSelectedImage: userSelectedImage, post_image: post_image})
  }

  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: []
      });
    } else {
      this.setState({
        openedCollapses: [collapse]
      });
    }
  };

  handleChange(e) {
    console.log("handleChange", e.target.name, e.target.value)
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  isValidPostTitle() {
    const {post_title} = this.state
    let valid = atLeastOneLetter(post_title) 
    return valid 
    
  }

isValidPostCaption() {
  const {post_caption} = this.state
  let valid = atLeastOneLetter(post_caption) 
  return valid 
  
}



  

    createPost() {
      console.log("Create Post Called")
      const { actionButtonPressed } = this.state 
      if (actionButtonPressed) {
        return 
      }
      this.setState({
        actionButtonPressed: true 
      })

      
      const mediaType = 'Image';
      if (this.state.post_image !== null && this.isValidPostCaption() && this.isValidPostTitle() ) {
        const args = {
          image: this.state.post_image,
          comment: this.state.post_caption,
          mediaType: mediaType,
          title: this.state.post_title, 
        }
        this.props.dispatch(postsActions.createPost(args, (data) => {

          this.props.dispatch(notificationMessageActions.addNotificationToQueue( (data.success ? data.message : data), (data.success ? 1 : 2)) )

          //   data.success ? {message: data.message, color: 1} : {message: data, color: 2}] })
            if (data.success) {
              this.toggleCreatePostModal()
              this.setState({ 
                post_caption: '',
                userSelectedPostImage: false ,
                post_image: null, 
                post_imageUrl: '',
                post_title: '',
                actionButtonPressed: false 
               })
              
            } else {
              this.setState({ 
                actionButtonPressed: false 
               })
            }
    
          }));
    
        
      } else {
        if (this.state.post_image === null) {
          this.props.dispatch(notificationMessageActions.addNotificationToQueue("Posts must have pictures", 2 ))
          this.setState({actionButtonPressed: false })
        } else if ( !this.isValidPostCaption() ) {
          this.setState({actionButtonPressed: false })
          this.props.dispatch(notificationMessageActions.addNotificationToQueue("Your post captions must include letters.", 2 ))
        } else if ( !this.isValidPostTitle() ) {
          this.setState({actionButtonPressed: false })
          this.props.dispatch(notificationMessageActions.addNotificationToQueue("Your post title must include letters.", 2 ))
        }
      }
  }

  render() {
    const { fetchGetPosts, createPostReq } = this.props;
    const { isOwner } = this.state;
    const posts = this.props.posts
    return (
      <>
      <div className="accordion card-box-shadow">
          <div className="mb-0">
            <CardHeader className="d-flex align-items-center remove-after cursor-regular  "
                  role="tab"                            
                  aria-expanded={this.state.openedCollapses.includes(
                    "collapseOne"
                  )}
                  >
                  <div className="d-flex align-items-center">
                    <h3 className="mb-0"> Posts </h3>
                  </div>
                  <div className="text-right ml-auto pr-3">
                    {isOwner && 
                     <Button color="primary" outline onClick={() => this.toggleCreatePostModal()}>
                      Create 
                    </Button>
                  
                    }
                  </div>
                </CardHeader> 
              </div>
            <Row>

            { !fetchGetPosts && posts.length > 0 && 
                <Col xs={12} sm={12} md={12} lg={12} className="mt-0">
                  <Post cursor img noShadow={true} imageUrl={posts[0].comments[0].media_url} post={posts[0]}> 
                  </Post>
                </Col>       
              }
            </Row>
          </div>
            <Row className="mt-4 ">
              { !fetchGetPosts && posts.length > 1 && 
              <Col xs={12 }className="text-center pb-4 mx-auto">
                <Button color="primary" size="sm" onClick={() => this.collapsesToggle("collapseOne")}>
                  <span>{this.state.openedCollapses.includes("collapseOne") ? "See Less" : "See More" } </span>
                </Button>
              </Col>
              }
              { !fetchGetPosts && posts.length < 1 && isOwner && 
                <Col className="text-center mx-auto pb-4 pt-4">
                  <Button color="primary" onClick={() => this.toggleCreatePostModal()}>
                    <span>Create Post</span>
                  </Button>
                </Col>   
              }
              <Collapse   
                role="tabpanel"               
                isOpen={this.state.openedCollapses.includes("collapseOne")}>
                { !fetchGetPosts && posts.length > 0 && posts.slice(1).map( (post, idx) => 
                    <Col xs={12} sm={12} md={12} lg={12} key={idx}  >
                      <Post cursor img imageUrl={post.comments[0].media_url} post={post}> 
                      </Post>
                    </Col>       
                  )}
              </Collapse>     
          </Row>
        <Row>
          <Modal
            isOpen={this.state.createPostModal}
            toggle={this.toggleCreatePostModal}
            className="modal-notice text-left"
            size="lg"
            fade={false}
          >
            <ModalHeader toggle={this.toggleCreatePostModal}>
              Create Post
            </ModalHeader>
            <ModalBody>
            <Row>
            <Col xs={12}>
                <FormGroup  >
                        <Input type="text" 
                        name="post_title"
                        placeholder="Enter a title for this post"               
                        value={this.state.post_title}
                        onChange={e => this.handleChange(e)}
                        />
                </FormGroup>
            </Col>
              <Col xs={12} sm={6} md={6} lg={4} >
                <PostUpload callback={this.handlePostImageChange}  imageUrl={this.state.post_imageUrl} />
              </Col>
              <Col xs={12} sm={6} md={6} lg={8} >
                <FormGroup >
                    <Input type="textarea" 
                      name="post_caption"
                      placeholder="Enter your caption"
                      rows="12"
                      cols="80"
                      style={{maxHeight:"275px"}}                        
                      value={this.state.post_caption}
                      onChange={e => this.handleChange(e)}
                    />
                </FormGroup>
              </Col>
            </Row>        
            </ModalBody>
            {(!this.state.actionButtonPressed && !createPostReq) ?  
              <ModalFooter className="text-center">
                <Col xs={12} sm={6} md={6} lg={4} className="ml-auto mr-auto">
                  <Button onClick={() => this.createPost() } color="primary" className="title" size={'lg'} >
                      Save Post
                  </Button>                
                </Col>
            </ModalFooter>
            : 
            (
            <ModalFooter className="text-center">
              <div className='sweet-loading'>
                  <ClipLoader
                    sizeUnit={"px"}
                    size={45}
                    color={'#f06482'}
                    
                  />
              </div>        
            </ModalFooter>
            )}
            
          </Modal>
        </Row>
      </>
    );
  }
}

PostsAccordion.propTypes = {
  // Which one of the component will be opened by default
  defaultOpened: PropTypes.number,
  // The Accordion will have no background color
  plain: PropTypes.bool,

  // example: [{title: "Title of the Collapse", text: "Text/Body of the Collapse"}]
};

function mapStateToProps(state) {
  const { memberInfo, postsData } = state;
  const { fetchGetPosts, createPostReq } = postsData; 

  const { fetchingInfo, info, fetchingUserInfo, userInfo } = memberInfo;

  return {
    fetchingInfo, info,
    fetchingUserInfo,  userInfo,
    fetchGetPosts, createPostReq
  };
}

const connectedPostsAccordion= connect(mapStateToProps)(PostsAccordion);
export { connectedPostsAccordion as PostsAccordion };
