import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import React from 'react';
import { createRef } from 'react';
// import PropTypes from "prop-types";
import { Button } from "components";
import { Row, } from "reactstrap";

import defaultImage from "assets/img/event_image_placeholder.jpg";
import defaultAvatar from "assets/img/event_image_placeholder.jpg";
import 'react-image-crop/dist/ReactCrop.css';
import { notificationMessageActions } from "_actions"
import setCanvasPreview from "./setCanvasPreview";

const ASPECT_RATIO = 1.911;
const MIN_DIMENSION = 150;

class EventImageUpload extends React.Component {
    constructor(props) {
      super(props);
      console.log("EventImageUpload", props)
      this.state = {
        file: null,
        croppedImageUrl: null,
        imagePreviewUrl: this.props.avatar   ? defaultAvatar : 
                         this.props.imageUrl ? this.props.imageUrl : 
                                               defaultImage,
        index: props.index !== undefined ? props.index : null,
        src: null,
        cropOpen: false,
        crop: {
          aspect: 1.911/1,
          width: 50,
          x: 0,
          y: 0,
        }, 
        imgSrc: "",
        error: "",
        imgRef: createRef(),
        previewCanvasRef: createRef()
      };
    //   this.handleImageChange = this.handleImageChange.bind(this);
    //   this.handleSubmit = this.handleSubmit.bind(this);
      this.handleClick = this.handleClick.bind(this);
      this.updateAvatar = this.updateAvatar.bind(this)
      this.setCrop = this.setCrop.bind(this)
      this.setImgSrc = this.setImgSrc.bind(this)
      this.setError = this.setError.bind(this)
    //   this.handleRemove = this.handleRemove.bind(this);
    }

    setCrop(e) {
      this.setState({
        crop: e
      })
    }

    setImgSrc(e) {
      this.setState({
        imgSrc: e
      })
    }

    setError(e) {
      this.setState({
        error: e
      })
    }


    updateAvatar(imgSrc, blob) {
      this.setState({ croppedImageUrl: imgSrc, blob: blob })
      this.handleImageChange()
    };

    onSelectFile = e => {
      const { error, croppedImageURL } = this.state
      let reader = new FileReader();
      const file = e.target.files?.[0];
      if (!file) return;
      
        if (e.target.files && e.target.files.length > 0) {
          const file = e.target.files[0]
          if (file.size > 7500000) {
            this.props.dispatch(notificationMessageActions.addNotificationToQueue("Your image must be less than 7.5 mb.", 2 ))
              return
          } else {
            reader.addEventListener("load", () => {
              const imageElement = new Image();
              const imageUrl = reader.result?.toString() || "";
              imageElement.src = imageUrl;
        
              imageElement.addEventListener("load", (e) => {
                if (error) {this.setError("") }
                const { naturalWidth, naturalHeight } = e.currentTarget;
                if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
                  this.setError("Image must be at least 150 x 150 pixels.");
                  return this.setImgSrc("");
                }
              });
              this.setState({ src: reader.result, croppedImageUrl: null })
              this.setImgSrc(imageUrl);
            });
            reader.readAsDataURL(file);
          }
        }
      };

      handleClick() {
        this.refs.fileInput.click();
        this.setState({ cropOpen: true })
        console.log("ImageUpload: handleClick:", this.state)
      }
    
      onImageLoad = (e) => {
        const { width, height } = e.currentTarget;
        const cropWidthInPercent = (MIN_DIMENSION / width) * 100;
    
        const crop = makeAspectCrop(
          {
            unit: "%",
            width: cropWidthInPercent,
          },
          ASPECT_RATIO,
          width,
          height
        );
        const centeredCrop = centerCrop(crop, width, height);
        this.setCrop(centeredCrop);
      };
    

      handleImageChange(e) {
        console.log("handleImageChange", this.state)
        let reader = new FileReader();
        reader.onloadend = () => {
          this.setState({
            imagePreviewUrl: reader.result
          });
          this.props.callback({state: this.state, newImage: true})
        };
        reader.readAsDataURL(this.state.blob)
      }

    
    
      render() {
        const { crop, croppedImageUrl, imgRef, previewCanvasRef,  } = this.state;
        console.log("imageRef", this.imageRef)
        console.log("state/props", this.state, this.props)
        return (

            <div className="fileinput text-center full-width">

              <input type="file"  accept=".jpg, .jpeg, .png," onChange={this.onSelectFile} ref="fileInput" />
              <div className="event-image-container">
              {(!croppedImageUrl ) && 
                <img className="event-image"  src={this.state.imagePreviewUrl} alt="..." />
                }
              {croppedImageUrl && <img className="event-image"  alt="Crop" src={croppedImageUrl} />}
              </div>
              {this.state.imgSrc && !croppedImageUrl && 
                  <div className="text-center mb-3">
                  <ReactCrop
                      crop={crop}
                      onChange={(pixelCrop, percentCrop) => this.setCrop(percentCrop)}
                      keepSelection
                      aspect={ASPECT_RATIO}
                      minWidth={MIN_DIMENSION}
                    >
                      <img
                        ref={this.state.imgRef}
                        src={this.state.imgSrc}
                        alt="Upload"
                        style={{ maxHeight: "70vh" }}
                        onLoad={this.onImageLoad}
                      />
                    </ReactCrop>
                  <Row className="text-center mx-auto pt-2">
                    {!croppedImageUrl && 
                    <Button
                      size="sm" color="success" className = "mx-auto"
                      onClick={() => {

                        const croppedImageUrl = setCanvasPreview(
                          imgRef.current, // HTMLImageElement
                          previewCanvasRef.current, // HTMLCanvasElement
                          convertToPixelCrop(
                            crop,
                            imgRef.current.width,
                            imgRef.current.height
                          )
                        )
                        .then( 
                          resolve => {
                            const dataUrl = previewCanvasRef.current.toDataURL();
                            console.log("resolve", resolve, croppedImageUrl)
                            this.updateAvatar(dataUrl, resolve );

                            // this.setState({blob: resolve})
                          },
                          reject => {
                            const dataUrl = previewCanvasRef.current.toDataURL();
                            this.updateAvatar(dataUrl, reject );
                            console.log("reject", reject)
                            // this.setState({blob: reject})
                          }
                        )
                      }}
                    >
                      Confirm Image
                    </Button>
                    }
                    </Row>
                    
                      {crop && (
                        <canvas
                          ref={previewCanvasRef}
                          className="mt-4"
                          style={{
                            display: "none",
                            border: "1px solid black",
                            objectFit: "contain",
                            width: 150,
                            height: 150,
                          }}
                        />
                    )}
              </div>}
              <div className="mt-1">
                {(this.state.file === null || this.state.file === undefined) ? (
                  <Button className="btn-primary " simple size="sm" outline color="primary"  onClick={() => this.handleClick()}>
                    {this.state.croppedImageUrl !== null ? "Update Image" : "Update Image"}
                  </Button>
                ) : (
                  <span>
                    <Button simple color="default" onClick={() => this.handleClick()}>
                      Change
                    </Button>
                    {this.props.avatar ? <br /> : null}
                    <Button color="warning" simple onClick={(e) => this.handleImageChange(e)}>
                      <i className="fa fa-times" /> Save
                    </Button>
                  </span>
                )}
                  {this.state.file !== null &&
                      <Button simple color="primary"  onClick={() => this.handleClick()}>
                      Change
                  </Button> }
              </div>
              <div>
                
              </div>
          </div>
        );
      }
    }


      export default EventImageUpload;


