import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import React from 'react';
import { createRef } from 'react';

// import PropTypes from "prop-types";

import defaultImage from "assets/img/image_placeholder.jpg";
// import defaultAvatar from "assets/img/placeholder.jpg";
import 'react-image-crop/dist/ReactCrop.css';
import YouTube from 'react-youtube';
import SweetAlert from "components/react-bootstrap-sweetalert/SweetAlert.jsx";
import  {FaYoutube} from 'react-icons/fa'
import { Button, Row, Modal, ModalHeader, ModalBody, Card, CardImg, } from "reactstrap";
import { LargeMediaViewer } from "components";
import {parseMediaTypeFromUrl } from '_helpers';
import { notificationMessageActions } from "_actions"
import ImageCropper from "./CircularImageCropper";
import setCanvasPreview from "./setCanvasPreview";

const opts =  {
  height: '600',
  width: '600',
  playerVars: { // https://developers.google.com/youtube/player_parameters
    autoplay: 0
  }
};

const ASPECT_RATIO = 1;
const MIN_DIMENSION = 150;

class GalleryUpload extends React.Component {
    constructor(props) {
      super(props);
      console.log("ImageUpload", props)
      this.state = {
        file: null,
        croppedImageUrl: null,
        imagePreviewUrl: this.props.avatar   ? defaultImage : 
                         this.props.imageUrl ? this.props.imageUrl : 
                                               defaultImage,
        index: props.index !== undefined ? props.index : null,
        src: null,
        crop: {
          aspect: 1,
          width: 50,
          x: 0,
          y: 0,
        },
        
        modalNotice: false,
        mediaViewerModal: false,
        mountedTimestamp: props.mountedTimestamp ? props.mountedTimestamp : new Date().getTime(),
        imgSrc: "",
        error: "",
        imgRef: createRef(),
        previewCanvasRef: createRef()

      };
    //   this.handleImageChange = this.handleImageChange.bind(this);
    //   this.handleSubmit = this.handleSubmit.bind(this);
      // this.updateMediaAlert = this.updateMediaAlert.bind(this);
      //For YouTube (sweet alert)
      this.hideAlert = this.hideAlert.bind(this);
      this.addYoutubeVideoAlert = this.addYoutubeVideoAlert.bind(this);
      this.confirmYoutubeVideo = this.confirmYoutubeVideo.bind(this);
      this.toggleModalNotice = this.toggleModalNotice.bind(this);
      this.closeModalNotice = this.closeModalNotice.bind(this);
      this.toggleMediaViewerModal = this.toggleMediaViewerModal.bind(this)
      this.updateAvatar = this.updateAvatar.bind(this)
      this.setCrop = this.setCrop.bind(this)
      this.setImgSrc = this.setImgSrc.bind(this)
      this.setError = this.setError.bind(this)
   
    }

    setCrop(e) {
      this.setState({
        crop: e
      })
    }

    setImgSrc(e) {
      this.setState({
        imgSrc: e
      })
    }

    setError(e) {
      this.setState({
        error: e
      })
    }


    toggleModalNotice() {
      this.setState({
        modalNotice: !this.state.modalNotice
      });
    }

    toggleMediaViewerModal() {
       this.setState({
        mediaViewerModal: !this.state.mediaViewerModal,
      });
    }
  
  
    closeModalNotice() {
      this.setState({
        modalNotice: false,
        src: null,
        imgSrc: null, 
        imgRef: createRef(),
        previewCanvasRef: createRef
      });
    }

    cancelImageUpload() {
      this.setState({
        src: null,
        imgSrc: null, 
        imgRef: createRef(),
        previewCanvasRef: createRef()
      });   
    }

     onSelectFile = (e) => {
      const { error } = this.state
      const file = e.target.files?.[0];
      if (!file) return;
  
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const imageElement = new Image();
        const imageUrl = reader.result?.toString() || "";
        imageElement.src = imageUrl;
  
        imageElement.addEventListener("load", (e) => {
          if (error) this.setError("");
          const { naturalWidth, naturalHeight } = e.currentTarget;
          if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
            this.setError("Image must be at least 150 x 150 pixels.");
            return this.setImgSrc("");
          }
        });
        this.setImgSrc(imageUrl);
      });
      reader.readAsDataURL(file);
    };
  

    onSelectFile = e => {
      const {error} = this.state 
      console.log("handleImageChange", this.state)
      e.preventDefault();
      let reader = new FileReader();
      let file = e.target.files[0];
      console.log("file size" , file.size)
      console.log("file type" , file.type)

      if ( file.type.includes("image/")) {
        if (e.target.files && e.target.files.length > 0) {
          const file = e.target.files[0]
          if (file.size > 7500000) {
            this.props.dispatch(notificationMessageActions.addNotificationToQueue("Your image must be less than 7.5 mb.", 2 ))
              return
          } else {
            reader.addEventListener("load", () => {
              const imageElement = new Image();
              const imageUrl = reader.result?.toString() || "";
              imageElement.src = imageUrl;
        
              imageElement.addEventListener("load", (e) => {
                if (error) {this.setError("") }
                const { naturalWidth, naturalHeight } = e.currentTarget;
                if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
                  this.setError("Image must be at least 150 x 150 pixels.");
                  return this.setImgSrc("");
                }
              });
              this.setState({ src: reader.result })
              this.setImgSrc(imageUrl);
            });
            reader.readAsDataURL(file);
          }
        }
      } else if ( file.type.includes("video/") && file.size > 100000000 )  {
        this.props.dispatch(notificationMessageActions.addNotificationToQueue("Videos must be less than 100 mb. If the video size limit is too small, you can upload the full-sized video to YouTube and add its url instead. ", 2 ))

          return
      } else {
        this.closeModalNotice()
        reader.onloadend = () => {
          this.setState({
            file: file,
            imagePreviewUrl: reader.result
          });
          this.props.callback({state: this.state, newImage: true})
        };
        reader.readAsDataURL(file);
      }
    }

    addYoutubeVideoAlert() {
      this.setState({
        alert: (
          <SweetAlert
            input
            showCancel
            style={{ display: "block", marginTop: "-100px" }}
            title="Enter YouTube Video URL"
            onConfirm={value => this.confirmYoutubeVideo(value)}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            confirmBtnText="Confirm"
            cancelBtnText="Cancel"
            btnSize="sm"
          />
        )
      });
    }
  
  
    confirmYoutubeVideo(url) {
      this.setState({ alert: url });
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      console.log("Url", url)
      const match = url.match(regExp);
      if ( match && match[7].length === 11 ){
          const parsedId = match[7];
          if (parsedId && this.state.index !== null) {
            console.log("parsedId", parsedId)
            this.props.youtubeCallback(parsedId, this.state.index)
            this.closeModalNotice()
          } else {
            this.props.dispatch(notificationMessageActions.addNotificationToQueue("Please refresh the page.", 2 ))
          }
      } else{
        this.props.dispatch(notificationMessageActions.addNotificationToQueue("Please try a different version of that YouTube URL. We were unable to verify it.", 2 ))
      }
      this.hideAlert()
      //setTimeout(this.displayCreatedBidAlert, 1000);
    }
  
      hideAlert() {
      this.setState({
        alert: null
      });
  
      }

      handleMediaType(type) {
        this.hideAlert()
        if (type === "media") {
          this.refs.fileInput.click();
        } else if (type === "youtube")  {
          this.addYoutubeVideoAlert()
          //sweetalert
        }        
        console.log("ImageUpload: handleClick:", this.state)
      }

      _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
      }

       onImageLoad = (e) => {
        const { width, height } = e.currentTarget;
        const cropWidthInPercent = (MIN_DIMENSION / width) * 100;
    
        const crop = makeAspectCrop(
          {
            unit: "%",
            width: cropWidthInPercent,
          },
          ASPECT_RATIO,
          width,
          height
        );
        const centeredCrop = centerCrop(crop, width, height);
        this.setCrop(centeredCrop);
      };

      
    
      // onImageLoaded = (image, pixelCrop) => {
      //   this.imgRef = image;
    
      //   // Make the library regenerate aspect crops if loading new images.
      //   const { crop } = this.state;
    
      //   if (crop.aspect && crop.height && crop.width) {
      //     this.setState({
      //       crop: { ...crop, height: null },
      //     });
      //   } else {
      //     this.makeClientCrop(crop, pixelCrop);
      //   }
      // };
    
      // onCropComplete = (crop, pixelCrop) => {
      //   this.makeClientCrop(crop, pixelCrop);
      // };
    
      // onCropChange = crop => {
      //   this.setState({ crop });
      // };
    
      // async makeClientCrop(crop, pixelCrop) {
      //   if (this.state.imgRef && crop.width && crop.height) {
      //     const croppedImageUrl = await this.getCroppedImg(
      //       this.state.imgRef,
      //       pixelCrop,
      //       'newFile.jpeg',
      //     )
      //     .then( 
      //       resolve => {
      //         console.log("resolve", resolve, croppedImageUrl)
      //         this.setState({blob: resolve})
      //       },
      //       reject => {
      //         console.log("reject", reject)
      //         this.setState({blob: reject})
      //       }
      //     );
      //   }
      // }


    
      // getCroppedImg(image, pixelCrop, fileName) {
      //   const canvas = document.createElement('canvas');
      //   canvas.width = pixelCrop.width;
      //   canvas.height = pixelCrop.height;
      //   const ctx = canvas.getContext('2d');
    
      //   ctx.drawImage(
      //     image,
      //     pixelCrop.x,
      //     pixelCrop.y,
      //     pixelCrop.width,
      //     pixelCrop.height,
      //     0,
      //     0,
      //     pixelCrop.width,
      //     pixelCrop.height,
      //   );

      //   return new Promise((resolve, reject) => {
      //     canvas.toBlob(blob => {
      //       blob.name = fileName;
      //       resolve(blob);
      //     }, 'image/jpeg');
      //   });

      // }

      handleImageChange(e) {
        console.log("handleImageChange", this.state)
        e.preventDefault();
        let reader = new FileReader();
        reader.onloadend = () => {
          this.setState({
            imagePreviewUrl: reader.result
          });
          this.props.callback({state: this.state, newImage: true})
        };
        reader.readAsDataURL(this.state.blob)
      }

      updateAvatar(imgSrc, blob) {
        this.setState({ croppedImageUrl: imgSrc, blob: blob })
      };

   
      render() {
        const { crop, croppedImageUrl, src, imagePreviewUrl, mountedTimestamp, imgRef, previewCanvasRef,  } = this.state;
        const mediaExt = imagePreviewUrl.split('.').pop()
        const isVideo = parseMediaTypeFromUrl(imagePreviewUrl) === "video"
        const isPhoto = parseMediaTypeFromUrl(imagePreviewUrl) === "image"
        const isYoutube =  !isPhoto &&!isVideo && !this.state.imagePreviewUrl.includes('http') && this.state.imagePreviewUrl.length > 0 
        console.log("imgRef", this.imgRef)
        console.log("state/props", this.state, this.props)
        // const date = new Date().getTime()
        return (
            <div className="fileinput text-center" style={{display:"inherit"}}>
              {this.state.alert}
              <input type="file" className="fileinput" accept=".mov, .mp4, .jpg, .jpeg, .png, .quicktime," onChange={this.onSelectFile} ref="fileInput" />
            <Card className ="mb-0">
              <div >
                { isVideo && 
                <div className="embed-responsive embed-responsive-1by1 card-img-top cursor-pointer" onClick={this.toggleMediaViewerModal}>
                  <video onContextMenu={e => e.preventDefault()} width="320" height="240" controls autoPlay={false} style={{pointerEvents: "none"}}>
                      <source src={this.state.imagePreviewUrl} type={"video/" + mediaExt} autostart="false"/>
                      Your browser does not support the video tag.
                  </video>
                </div>
                  }
              </div>
              {isYoutube && 
                <div onClick={this.toggleMediaViewerModal}>
                  <YouTube
                  videoId={this.state.imagePreviewUrl}
                  opts={opts}
                  onReady={this._onReady}
                  containerClassName="embed-responsive embed-responsive-1by1 card-img-top cursor-pointer"
                  className="pointer-events-none"
                  />
                </div>
                }
              { isPhoto &&
                <CardImg
                  alt="..."
                  src={`${this.state.imagePreviewUrl}?t=${mountedTimestamp}`}
                  // key={Math.random()}
                  onClick={this.toggleMediaViewerModal}
                  className="cursor-pointer"
                  top
                />
                 }
              </Card>
              <div className="mt-3">
                  <Button className="btn-primary" size="sm" color="primary"  onClick={() => this.toggleModalNotice()}>
                    {this.props.imageUrl ? "Update Media" : "Add Media"}
                  </Button>
                <br></br>
              </div>
              <Row>
                <Modal
                  isOpen={this.state.modalNotice}
                  toggle={this.toggleModalNotice}
                  className="modal-notice "
                  size="lg"
                  fade={false}
                  onClosed={this.closeModalNotice}
                >
                <ModalHeader toggle={this.toggleModalNotice}>
                  Select Media Type
                </ModalHeader>
                <ModalBody>
                {this.state.imgSrc && 
                  <div className="text-center">
                  <ReactCrop
                      crop={crop}
                      onChange={(pixelCrop, percentCrop) => this.setCrop(percentCrop)}
                      keepSelection
                      aspect={ASPECT_RATIO}
                      minWidth={MIN_DIMENSION}
                    >
                      <img
                        ref={this.state.imgRef}
                        src={this.state.imgSrc}
                        alt="Upload"
                        style={{ maxHeight: "70vh" }}
                        onLoad={this.onImageLoad}
                      />
                    </ReactCrop>
                  <Row className="text-center mx-auto pt-2">
                    <Button
                      outline size="sm" color="info" className = "mx-auto"
                      onClick={() => {

                        const croppedImageUrl = setCanvasPreview(
                          imgRef.current, // HTMLImageElement
                          previewCanvasRef.current, // HTMLCanvasElement
                          convertToPixelCrop(
                            crop,
                            imgRef.current.width,
                            imgRef.current.height
                          )
                        )
                        .then( 
                          resolve => {
                            const dataUrl = previewCanvasRef.current.toDataURL();
                            console.log("resolve", resolve, croppedImageUrl)
                            this.updateAvatar(dataUrl, resolve );

                            // this.setState({blob: resolve})
                          },
                          reject => {
                            const dataUrl = previewCanvasRef.current.toDataURL();
                            this.updateAvatar(dataUrl, reject );
                            console.log("reject", reject)
                            // this.setState({blob: reject})
                          }
                        )
                      }}
                    >
                      Crop Image
                    </Button>
                    </Row>
                    
                      {crop && (
                        <canvas
                          ref={previewCanvasRef}
                          className="mt-4"
                          style={{
                            // display: "none",
                            border: "1px solid black",
                            objectFit: "contain",
                            width: 150,
                            height: 150,
                          }}
                        />
                    )}
                  <div className="pt-2"> 
                      {(this.state.imgRef === null || this.state.imgRef === undefined) ? (
                        // <Button className="btn-primary" simple color="primary"  onClick={() => this.handleClick()}>
                        //   {this.props.avatar ? "Add Profile Photo" : "Update Profile Photo"}
                        // </Button>
                        <span></span>
                      ) : (
                        <span>
                          <Button outline size="sm" color="default" onClick={() => this.cancelImageUpload()}>
                            Change
                          </Button>
                          {this.props.avatar ? <br /> : null}
                          <Button outline size="sm"  color="primary" onClick={(e) => this.handleImageChange(e)}>
                            Save
                          </Button>
                        </span>
                      )}
                        {this.state.file !== null &&
                            <Button outline size="sm"  color="default"  onClick={() => this.cancelImageUpload()}>
                            Change
                        </Button> }
                    </div>

              </div>}
                  {!src && 
                  <div className="text-center">
                    <Button className="btn-primary" size="sm" color="primary"  onClick={() => this.handleMediaType("media")}>
                      {this.state.imagePreviewUrl === defaultImage ? "Add Media" : "Update Media"}
                    </Button>
                    <br/>
                    <Button className="btn-youtube" style={{marginTop:'10px'}} size="sm" onClick={() => this.handleMediaType("youtube")}>
                      <FaYoutube color='#ffffff' size={25}/> &nbsp;&nbsp;&nbsp;&nbsp;{"Add YouTube Video"}
                    </Button>
                  </div>
                  }
                </ModalBody>

                </Modal>
              </Row>
              <Row>
                <Modal
                  isOpen={this.state.mediaViewerModal}
                  toggle={this.toggleMediaViewerModal}
                  modalClassName="text-center"
                  fade={false}
                  size="lg"
                >
                  <ModalHeader toggle={this.toggleMediaViewerModal}> Media </ModalHeader>
                  <ModalBody>
                    {this.state.imagePreviewUrl  && 
                      <LargeMediaViewer img imageUrl={this.state.imagePreviewUrl } />
                    }
                  </ModalBody>
                </Modal>
            </Row>
          </div>

        );
      }
    }


      export default GalleryUpload;


